@use "../../../css/imports.scss";

.photo {
    display: flex;
    flex-direction: column;
    max-width: 256px;
    // background: imports.$sec-white;
    border: solid 1px imports.$ter-grey;
    cursor: pointer;
    transition: background-color 0.25s, transform 0.25s;

    .photo__image {
        flex: 1;
        transition: filter 0.25s ease;

        img {
            width: 100%;
            object-fit: cover;
        }
    }

    .photo__operations {
        padding: 0.25rem;
        flex: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }

    &:hover {
        transform: scale(0.95);

        .photo__image {
            filter: brightness(0.875);
        }
    }

    &:active {
        transition-duration: 0.125s;
        transform: scale(0.95) translateY(0.25rem);
    }

    &.selected {
        outline: solid 1px imports.$dark-red;
        transform: scale(0.9);
        border: none;
        background-color: imports.$sec-white;

        .photo__image {
            filter: brightness(0.75);
        }
    }
}
