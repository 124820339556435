@use "../../css/imports.scss";

.secret {
    a {
        text-decoration: none;

        .card {
            color: imports.$prim-black;
            width: 100%;
            padding: 0.5rem;
            margin: 1rem auto;
            background: imports.$sec-white;
            text-align: center;
            transition: color 0.25s ease;

            .title {
                letter-spacing: 0.0625rem;
                margin: 0.5rem;
                font-weight: bold;
            }

            span {
                display: block;
                margin: 0.25rem;
                color: imports.$ter-black;
                transition: color 0.25s ease;
            }
        }

        &:hover {
            .card {
                color: imports.$not-as-light-red;
                span {
                    color: imports.$light-red;
                }
            }
        }

        &:active {
            .card {
                color: imports.$even-darker-red;
                span {
                    color: imports.$dark-red;
                }
            }
        }
    }
}
