@use "../../css/imports.scss";

.galleryEdit {
    .galleryEdit__editDate {
        color: imports.$ter-black;
        font-size: 0.75rem;
        font-style: italic;
    }

    .inputGroup {
        display: flex;
        flex-direction: row;
        align-items: center;

        label {
            flex: 0;
            white-space: nowrap;
            color: imports.$sec-black;
            font: 700 0.875rem imports.$open-sans;
        }
        input[type="text"] {
            @include imports.text-inputs;
            flex: 1;
            margin: 0.5rem 0 0.5rem 0.5rem;
        }
    }

    .galleryEdit__operations {
        margin: 0.5rem 0;
        button {
            @include imports.btn-inputs-prim;
            margin: 0.25rem 0.5rem 0 0;
        }
    }

    .galleryEdit__divider {
        font: 700 1.25rem imports.$open-sans;
        color: imports.$prim-black;
        padding: 0.5rem 0;
        border-bottom: solid 1px imports.$ter-grey;
        margin: 1rem 0;
    }

    .galleryEdit__photoGrid {
        display: grid;
        grid-template-columns: repeat(2, minmax(64px, 256px));
        gap: 0.5rem;
        margin: auto;
    }
}

@media screen and (min-width: 512px) {
    .galleryEdit .galleryEdit__photoGrid {
        grid-template-columns: repeat(3, minmax(64px, 256px));
    }
}

@media screen and (min-width: 768px) {
    .galleryEdit .galleryEdit__photoGrid {
        grid-template-columns: repeat(4, minmax(64px, 256px));
    }
}
