@use "imports.scss";

* {
    box-sizing: border-box;
}

html,
body {
    width: 100%;
    margin: 0;
    padding: 0;
    font: 400 16px imports.$open-sans;
    color: imports.$prim-black;
}

p {
    line-height: 150%;
    margin: 1rem auto;
    color: imports.$ter-black;
}

li {
    color: imports.$ter-black;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: imports.$light-gold;
    font-weight: 700;
    margin: 1rem auto;
    text-align: center;
    letter-spacing: 0.0625rem;
}

a {
    text-decoration: none;
    color: imports.$light-red;
    transition: color 0.25s ease;
    &:hover {
        color: imports.$not-as-light-red;
    }
    &:active {
        color: imports.$dark-red;
    }
}

h1 {
    font-size: 3rem;
}
h2 {
    font-size: 2.5rem;
}
h3 {
    font-size: 2rem;
}
h4 {
    font-size: 1.75rem;
}
h5 {
    font-size: 1.5rem;
}
h6 {
    font-size: 1.25rem;
}

.app {
    width: 100%;
}

.content-wrap {
    width: 90%;
    margin: auto;
}

a.link-button {
    display: block;
    margin: 1rem auto 0;
    border: solid 0.125rem imports.$not-as-light-gold;
    color: imports.$light-gold;
    font: 400 1rem imports.$open-sans;
    text-decoration: none;
    letter-spacing: 0.0625rem;
    padding: 0.5rem;
    text-align: center;
    transition: color 0.25s ease, border-color 0.25s ease, background 0.25s ease;

    &:hover {
        border-color: imports.$not-as-light-red;
        color: imports.$light-red;
    }

    &:active {
        border-color: imports.$dark-red;
        background: imports.$dark-red;
        color: imports.$prim-white;
    }
}

@media screen and (min-width: 768px) {
    .content-wrap {
        max-width: 900px;
    }
}
