@use "../../css/imports.scss";

a.iconButton,
a.iconButton:hover {
    color: imports.$ter-black;
    text-decoration: none;
}

.iconButton {
    @include imports.btn-inputs-sec;
    display: flex;
    align-items: center;
    justify-content: center;
    color: imports.$ter-black;

    svg {
        color: imports.$ter-black;
        size: 2rem;
        margin: 0.25rem;
    }

    &.caption_row {
        flex-direction: row;
    }
    &.caption_column {
        flex-direction: column;
    }

    .iconButtonCaption {
        flex: 0;
        margin: 0.25rem;
    }
}

@media screen and (max-width: 500px) {
    .iconButton .iconButtonCaption {
        display: none;
    }
}
