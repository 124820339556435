@use "../../css/imports.scss";

.home {
    p {
        text-align: center;
    }

    .feedback {
        margin: auto;
        text-align: center;
    }

    .form {
        width: 100%;

        input {
            font: 400 1rem imports.$open-sans;
            color: imports.$ter-black;
            outline: none;
        }

        input[type="email"],
        input[type="password"] {
            display: block;
            width: 100%;
            margin: 0.5rem auto;
            padding: 0.5rem;
            border: none;
            background: imports.$sec-white;
            transition: background 0.25s ease;

            &:focus {
                background: imports.$ter-white;
            }
        }

        .buttonContainer {
            display: flex;
            flex-direction: row;

            button:last-child {
                margin-left: 0.5rem;
            }
        }

        button {
            display: block;
            border: solid 0.125rem imports.$ter-black;
            color: imports.$ter-black;
            background: none;
            padding: 0.25rem 0.5rem;
            width: 100%;
            transition: border-color 0.25s ease, color 0.25s ease,
                background 0.25s ease;
            cursor: pointer;

            &:hover {
                border-color: imports.$not-as-light-red;
                color: imports.$light-red;
            }

            &:active {
                background: imports.$not-as-light-red;
                color: imports.$prim-white;
            }

            &.googleBtn {
                display: flex;
                align-items: center;
                justify-content: center;

                svg {
                    margin-right: 0.25rem;
                }
            }
        }
    }

    @media screen and (min-width: 480px) {
        .form {
            max-width: 480px;
            margin: auto;

            button {
                display: inline-block;
                width: auto;
            }
        }
    }
}
