@use "../../css/imports.scss";

.searchBar {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.25rem;
    background: imports.$ter-white;
    cursor: text;

    button.searchBar__btn {
        border: none;
        background: none;
        outline: none;
        cursor: pointer;
        transition: transform 0.25s;

        &:hover {
            transform: translateY(1px);
        }

        &:active {
            transform: translateY(4px);
        }

        svg.searchBar__icon {
            flex: 0 2em;
            color: imports.$ter-black;
        }
    }

    input[type="text"].searchBar__input {
        flex: 1;
        outline: none;
        border: none;
        background: none;
        font: 1rem imports.$open-sans;
    }
}
