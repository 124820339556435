@use "../../css/imports.scss";

.emails {
    .feedback {
        color: imports.$light-red;
        font-size: 0.75rem;
    }

    button.operationBtn {
        @include imports.btn-inputs-prim;
        flex: 0;
        padding: 0.25rem 0.5rem;
        border: solid 0.125rem imports.$not-as-light-gold;
        color: imports.$light-gold;
        margin: 0 0 0 0.25rem;
        background: none;
        transition: color 0.25s ease, border-color 0.25s ease,
            background 0.25s ease;
        display: block;
        cursor: pointer;
        white-space: nowrap;

        &.extra {
            border-color: imports.$even-darker-gold;
            color: imports.$even-darker-gold;
            font-weight: 700;
        }
    }

    .operations {
        display: flex;
        margin: 1rem auto;
    }

    .form {
        display: flex;
        margin: 1rem auto;

        input {
            font: 400 1rem imports.$open-sans;
            color: imports.$sec-black;
        }

        input[type="text"] {
            flex: 1;
            padding: 0.25rem;
            background: imports.$ter-white;
            margin-right: 0.25rem;
            outline: none;
            border: none;

            &:placeholder {
                color: imports.$ter-black;
            }
        }
    }

    .email-search-con {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;

        .email-searchbar {
            flex: 1;
        }

        .email-count {
            flex: 0 9rem;
            white-space: nowrap;
            margin-left: 1rem;
            text-align: right;
        }
    }

    .email-list {
        background: imports.$sec-white;
        padding: 1rem 0;
        margin: 1rem auto 2rem;

        .email {
            width: 100%;
            margin: auto;
            text-align: left;
            padding: 0.5rem 1rem;
            display: flex;
            align-items: center;
            transition: all 0.25s ease;

            &.selected {
                outline-color: imports.$light-red;
                outline-style: solid;
                outline-width: 1px;
                padding: 0.5rem 1.1rem;
            }

            .label {
                color: imports.$ter-black;
                flex: 1;
                display: flex;
                flex-direction: column;

                .label__email {
                    font-size: 0.875rem;
                    font-weight: 700;
                    margin-bottom: 0.25rem;
                }

                .label__name {
                    font-size: 0.75rem;
                    color: imports.$ter-grey;
                }
            }

            input[type="checkbox"] {
                margin-right: 1rem;
            }

            .emailIconBtnGroup {
                display: flex;
                flex-direction: column;
                margin: 0 0.5rem;

                .emailIconBtnGroup__label {
                    white-space: nowrap;
                    font-size: 0.75rem;
                    color: imports.$ter-black;
                }
            }

            .delete {
                cursor: pointer;
                flex: 0 2rem;
                padding: 0.5rem;
                background: imports.$ter-white;
                width: 2rem;
                transition: background 0.25s ease;
                border: none;

                img {
                    width: 100%;
                }

                &:hover {
                    background: imports.$light-red;
                }
                &:active {
                    background: imports.$not-as-light-red;
                }
            }

            &:last-child {
                border-bottom: none;
            }
        }
    }
}
