@use "../../../css/imports.scss";

.album {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.5rem;
    margin: 0.5rem auto;
    background: imports.$sec-white;
    border: solid 1px imports.$prim-grey;

    .album__text {
        flex: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .album__header,
        .album__info {
            white-space: nowrap;
        }

        .album__header {
            font-weight: 700;
            font-size: 1.25rem;
            color: imports.$sec-black;
            margin: 0.5rem 0;

            .album__name {
                margin-bottom: 0.25rem;
            }

            .album__publishStatus {
                font: 400 0.625rem imports.$open-sans;
                color: imports.$ter-white;
                background: imports.$ter-grey;
                flex: 0;
                padding: 0.125rem 0.25rem;
                border-radius: 4px;
                display: inline-flex;

                svg {
                    margin-right: 0.25rem;
                }
            }
        }

        .album__info {
            font-size: 0.75rem;
            color: imports.$ter-black;

            .albumInfo__count,
            .albumInfo__date {
                margin: 0.125rem 0;
            }
        }
    }

    .album__operations {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;

        .album__operationsBtn {
            flex: 0;
            max-width: 8rem;
            margin: 0.125rem 0;

            &:last-child {
                margin-left: 0.25rem;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .album {
        .album__text {
            flex-direction: row;
            align-items: center;
            flex: 3;

            .album__header {
                font-size: 1rem;
                flex: 1;
                white-space: normal;
            }

            .album__info {
                flex: 2;
            }
        }

        .album__operations {
            flex-direction: row;
            justify-content: flex-end;
            flex: 2;
        }
    }
}
