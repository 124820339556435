@use "../../css/imports.scss";

.navbar {
    width: 90%;
    margin: auto;
    padding: 0.5rem 0;

    .desktop {
        display: none;
    }
}

@media screen and (min-width: 768px) {
    .navbar {
        .mobile {
            display: none;
        }

        .desktop {
            display: block;
            margin: auto;
            text-align: center;

            .item {
                display: inline;
                padding: 0.5rem 1rem;

                a {
                    text-decoration: none;
                    text-transform: uppercase;
                    letter-spacing: 0.0625rem;
                    font-weight: 600;
                    color: imports.$sec-black;
                    transition: color 0.25s ease;

                    &:hover {
                        color: imports.$light-red;
                    }

                    &:active {
                        color: imports.$not-as-light-red;
                    }
                }
            }
        }
    }
}
