@use "../../css/imports.scss";

.booklets {
    .booklet-list {
        padding: 1rem 0;

        .booklet {
            background: imports.$sec-white;
            margin: 0.5rem auto;
            padding: 1rem;
            display: flex;
            align-items: flex-start;
            flex-direction: column;

            .info {
                flex: 1;

                .title {
                    font-weight: 600;
                    letter-spacing: 0.0625rem;
                    color: imports.$sec-black;
                    margin: 0.25rem 0;
                }

                .id,
                a {
                    font-size: 0.875rem;
                    margin-left: 1rem;
                }
            }

            .operations {
                flex: 0;
                margin: 0.5rem;

                img {
                    width: 2rem;
                    padding: 0.5rem;
                    background: #d0d0d0;
                    cursor: pointer;
                    transition: background 0.25s ease;

                    &:hover {
                        background: imports.$light-red;
                    }
                    &:active {
                        background: imports.$not-as-light-red;
                    }
                }
            }
        }
    }
}

.booklet-upload {
    .pg-title {
        font-weight: 700;
        font-size: 1.25rem;
        letter-spacing: 0.0625rem;
        color: imports.$prim-black;
        margin: 1rem 0;
    }

    .form {
        input[type="text"] {
            @include imports.text-inputs;
            display: block;
            margin: 0.5rem 0;
            width: 100%;
        }

        input[type="file"] {
            display: none;
        }

        .buttons {
            display: flex;
            flex-direction: row;

            button {
                @include imports.btn-inputs-prim;
                display: block;
            }
        }
    }
}

@media screen and (min-width: 500px) {
    .booklet-upload {
        .form {
            input[type="text"] {
                width: 450px;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .booklets {
        .booklet-list {
            .booklet {
                flex-direction: row;
                align-items: center;
            }
        }
    }
}
