@use "../../css/imports.scss";

.gallery {
    .galleryOps {
        display: flex;
        flex-direction: row;
        margin: 0.5rem auto;
    }

    .galleryAlbums {
        padding: 0;
        list-style: none;
    }
}
