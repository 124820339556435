@use "../../css/imports.scss";

.posts {
    .post-list {
        padding: 1rem 0;

        .post {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;
            background: imports.$sec-white;
            margin: 0.5rem auto;
            padding: 1rem;

            .info {
                flex: 1;

                .title {
                    font-weight: 600;
                    letter-spacing: 0.0625rem;
                    color: imports.$sec-black;
                    margin: 0.25rem 0;
                }

                .visibility,
                .date,
                .event-date,
                .commit-notif,
                a {
                    font-size: 0.875rem;
                    margin-left: 1rem;
                    color: imports.$ter-black;
                }

                .commit-notif {
                    font-weight: 600;
                    font-style: italic;
                    color: imports.$dark-red;
                }

                .visibility {
                    display: inline-flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 0.125rem 0.25rem;
                    background: imports.$ter-grey;
                    color: imports.$ter-white;
                    border-radius: 4px;
                    margin-top: 0.25rem;

                    svg {
                        margin-right: 0.25rem;
                    }
                }
            }

            .operations {
                flex: 0;
                display: flex;
                flex-direction: row;

                button {
                    margin: 0.25rem 0.5rem;
                }
            }
        }
    }
}

.post-create {
    .pg-title {
        font-weight: 700;
        font-size: 1.25rem;
        letter-spacing: 0.0625rem;
        color: imports.$prim-black;
        margin: 1rem 0;
    }

    .feedback {
        color: imports.$dark-red;
    }

    .form {
        .event-info {
            display: block;
            &.hidden {
                display: none;
            }
        }

        .post-types {
            margin: 0.5rem auto;

            .radio-group {
                display: inline-flex;
                align-items: center;
                margin-right: 0.75rem;
                vertical-align: text-bottom;

                label {
                    margin: 0.375rem 0 0;
                    font-weight: 600;
                }
            }
        }

        .input-group {
            display: inline-block;
            margin: 0.5rem auto;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            label {
                margin-right: 0.5rem;
                flex: 0;
                white-space: nowrap;
            }

            input[type="text"] {
                display: inline-block;
                width: initial;
                flex: 1;
            }

            #start-datetime,
            #end-datetime,
            #location {
                margin: 0.25rem 0;
            }
        }

        input[type="radio"] {
            @include imports.radio-inputs;
        }

        input[type="text"],
        textarea {
            @include imports.text-inputs;
            width: 100%;
            display: block;
        }

        textarea {
            resize: none;
            height: 20rem;
        }

        input[type="datetime-local"] {
            @include imports.text-inputs;
            display: inline-block;
            height: 1.75rem;
            appearance: none;
        }

        input[type="file"] {
            display: none;
        }
    }

    .postCreate__buttons {
        button {
            @include imports.btn-inputs-prim;
            margin: 0.5rem 0.25rem 0.5rem 0;
        }
    }
}

.form {
    opacity: 1;
    transition: opacity 0.5s ease, max-height 0.5s ease;

    &.hidden {
        opacity: 0;
        max-height: 0;
        display: none;
    }
}

.ReactModal__Overlay .ReactModal__Content .emailModal {
    .modalRadioButtons {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .modalRadioGroup {
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            max-width: 15rem;
            margin: 0.5rem;

            input[type="radio"] {
                @include imports.radio-inputs;
                margin: 0 0.125rem;
            }

            label {
                font-size: 0.875rem;
                flex: 1;

                .overQuota {
                    color: imports.$dark-red;
                }
            }
        }
    }

    .modalCheckboxes {
        overflow-y: scroll;
        height: 22rem;

        .modalCheckboxGroup {
            list-style: none;
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 0.25rem 0;

            label {
                font-size: 0.75rem;
                color: imports.$ter-black;
            }

            input[type="checkbox"]:disabled ~ label {
                opacity: 0.5;
            }
        }
    }

    .closeBtn {
        @include imports.btn-inputs-prim;
    }
}

@media screen and (min-width: 768px) {
    .posts {
        .post-list {
            .post {
                flex-direction: row;
                align-items: center;

                .operations {
                    flex-direction: column;
                }
            }
        }
    }

    .post-create {
        .form .input-group {
            flex-direction: row;
            align-items: center;
            justify-content: start;
        }
    }
}
