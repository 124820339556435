@use "../../../css/imports.scss";

.ReactModal__Overlay .ReactModal__Content {
    .photoModal {
        img {
            width: 80%;
            max-height: 500px;
            margin: 0.5rem auto;
            object-fit: contain;
            display: block;
        }

        .photoModal__editTimestamp {
            color: imports.$ter-grey;
            font-size: 0.75rem;
            margin: 0.5rem 0;
        }

        .photoModal__operations {
            display: flex;
            flex-direction: column;

            input[type="text"] {
                @include imports.text-inputs;
                flex: 1;
                margin: 0.25rem 0;
            }

            button {
                @include imports.btn-inputs-prim;
                margin: 0.25rem 0;
            }
        }
    }

    .uploadModal {
        width: 90%;
        margin: auto;

        .dropzone {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 1rem;
            margin: 1rem auto;
            background: imports.$sec-white;
            border: dashed 1px imports.$prim-grey;
            height: 300px;

            &:hover {
                border-color: imports.$light-gold;
                border-width: 2px;
            }

            p {
                color: imports.$ter-grey;
            }

            button {
                @include imports.btn-inputs-sec;
            }

            &.animated {
                animation: drop 0.5s ease;
            }
        }

        button#doneBtn {
            @include imports.btn-inputs-prim;
        }

        .uploadModal__rejections {
            padding: 0;

            #rejectionHeader {
                font: 700 1.25rem imports.$open-sans;
                color: imports.$even-darker-red;
                margin-bottom: 0.5rem;
            }

            .rejection {
                margin-left: 1.25rem;

                .rejection__filename {
                    font: 700 0.875rem imports.$open-sans;
                    color: imports.$ter-black;
                }

                .rejection__reasons {
                    font-size: 0.75rem;
                    color: imports.$light-red;
                }
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .ReactModal__Overlay .ReactModal__Content {
        .photoModal {
            .photoModal__operations {
                flex-direction: row;
                input[type="text"],
                button {
                    margin: 0 0.5rem 0 0;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}

@keyframes drop {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(0.975);
    }
    100% {
        transform: scale(1);
    }
}
