@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap");

$open-sans: "Open Sans", sans-serif;

/* Primary accent */
$light-gold: #d9d59a;
$not-as-light-gold: #c2be88;
$dark-gold: #c0ba62;
$even-darker-gold: #b3ad5b;

/* Secondary accent */
$light-red: #d99aa2;
$not-as-light-red: #c28a91;
$dark-red: #c0525e;
$even-darker-red: #a34651;

/* For text color */
$prim-black: #101010;
$sec-black: #383838;
$ter-black: #606060;

/* For white background */
$prim-white: #ffffff;
$sec-white: #f0f0f0;
$ter-white: #e8e8e8;
$qua-white: #e0e0e0;

/* Greys */
$prim-grey: #c0c0c0;
$sec-grey: #b0b0b0;
$ter-grey: #a0a0a0;

/* --- Mixins --- */

@mixin centered-flex() {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin inputs() {
    font: 400 1rem $open-sans;
    color: $sec-black;
    // margin: .5rem 0;
}

@mixin text-inputs() {
    @include inputs;
    padding: 0.25rem;
    background: $ter-white;
    margin-right: 0.25rem;
    outline: none;
    border: none;
    transition: background 0.25s ease;

    &:placeholder {
        color: $ter-black;
    }

    &:focus {
        background: #d8d8d8;
    }
}

@mixin btn-inputs-prim() {
    @include inputs;
    letter-spacing: 0.0625rem;
    padding: 0.5rem;
    border: solid 0.125rem $dark-gold;
    color: $even-darker-gold;
    background: none;
    transition: color 0.25s ease, border-color 0.25s ease, background 0.25s ease;
    cursor: pointer;

    &:hover {
        border-color: $not-as-light-red;
        color: $light-red;
    }

    &:active {
        border-color: $dark-red;
        background: $dark-red;
        color: $prim-white;
    }

    &:disabled {
        border-color: $ter-grey;
        color: $sec-grey;
        cursor: default;
    }
}

@mixin btn-inputs-sec() {
    border: solid 1px $prim-grey;
    cursor: pointer;
    transition: all 0.25s ease;
    background: $ter-white;
    padding: 0.25rem;
    font-size: 0.75rem;
    color: $ter-black;
    text-align: center;
    white-space: nowrap;

    &:hover {
        opacity: 0.8;
    }

    &:active {
        transform: scale(0.95);
    }

    &:disabled {
        opacity: 0.6 !important;
        transform: none !important;
        cursor: default;
    }
}

@mixin radio-inputs() {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    border-radius: 50%;
    width: 1.25rem;
    height: 1.25rem;
    border: 0.125rem solid $dark-gold;
    transition: all 0.25s ease;
    outline: none;

    &:checked {
        border-width: 0.5rem;
    }
}
