@use "../../../css/imports.scss";

.mediaList {
    padding: 0;
    margin: 0.5rem auto;
    width: 90%;
    max-width: 600px;
    max-height: 200px;
    overflow-y: scroll;

    .mediaListItem {
        list-style: none;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0.25rem;
        margin: 0.5rem auto;
        background: imports.$sec-white;
        border: solid 1px imports.$light-gold;
        border-radius: 4px;
        color: imports.$ter-black;

        img {
            flex: 0 3.5rem;
            width: 3.5rem;
            object-fit: contain;
        }

        .mediaListItemInfo {
            flex: 1;
            padding: 0 0.5rem;
            font-size: 0.75rem;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        button.mediaListDeleteBtn {
            border: none;
            background: none;
            flex: 0;
        }
    }
}
